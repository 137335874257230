import React from "react";
import styles from "./ModeratorControls.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTicketAlt,
    faEye,
    faBan,
    faEyeSlash,
    faHandPaper
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import EnterTicketForm from "./EnterTicketForm";
import { pointingSessionSelector } from "./PointingSessionSlice";
import { useSelector } from "react-redux";
import WebSocketClient from "../../app/wsClient";

const ModeratorControls = () => {
    const [modalIsOpen, setIsOpen] = React.useState(false);

    let pointingSession = useSelector(pointingSessionSelector);
    let ws = new WebSocketClient();

    const startNewTicket = (): void => {
        if (!pointingSession.revealScores && pointingSession.sessionHasActiveTicket) {
            const rconfirm = window.confirm("Scores for the current ticket have not been revealed. Are you sure you want to start a new ticket?");
            if (!rconfirm) {
                return;
            }
        }
        setIsOpen(true);
    }

    function afterOpenModal(): void {
        ws.send("closeTicket", { sessionId: pointingSession.sessionId });
    }

    const closeModal = (): void => setIsOpen(false);

    const stopModerating = (): void => {
        let payload = {
            "sessionId": pointingSession.sessionId,
            "isModerator": false
        };
        ws.send("updateClient", payload);
    }

    const revealScores = (): void => {
        let payload = {
            "sessionId": pointingSession.sessionId
        };
        ws.send("revealScores", payload);
    }

    const hideScores = () => {
        let payload = {
            "sessionId": pointingSession.sessionId
        };
        ws.send("hideScores", payload);
    }

    const toggleFistOfFive = () => {
        ws.send("closeTicket", {sessionId: pointingSession.sessionId});
        ws.send("addTicket", { description: "FIST OF FIVE", sessionId: pointingSession.sessionId });
    }

    return (
        <div className={styles.moderatorControlsContainer}>

            <div className={styles.modControlBtn} onClick={startNewTicket}>
                <div className={styles.modControlIcon}>
                    <FontAwesomeIcon icon={faTicketAlt} color="green" size="2x" />
                </div>
                <div className={styles.modControlText}>
                    START NEW TICKET
                </div>
                <div style={{clear: "both"}}></div>
            </div>

            <div className={styles.modControlBtn} onClick={(pointingSession.revealScores ? hideScores : revealScores)}>
                <div className={styles.modControlIcon}>
                    { !pointingSession.revealScores ?
                        <FontAwesomeIcon icon={ faEye } color="orange" size="2x" /> :
                        <FontAwesomeIcon icon={ faEyeSlash } color="orange" size="2x" />
                    }
                </div>
                <div className={styles.modControlText}>
                    { !pointingSession.revealScores ? "REVEAL" : "HIDE" } SCORES
                </div>
                <div style={{clear: "both"}}></div>
            </div>

            <div className={styles.modControlBtn} onClick={toggleFistOfFive}>
                <div className={styles.modControlIcon}>
                    <FontAwesomeIcon icon={faHandPaper} color={"brown"} size={"2x"} />
                </div>
                <div className={styles.modControlText}>
                    FIST OF FIVE
                </div>
                <div style={{clear: "both"}}></div>
            </div>

            <div className={`${styles.modControlBtn} ${styles.cancelMod}`} title={"Stop Moderating"} onClick={() => stopModerating()}>
                <div className={styles.modControlIcon}>
                    <FontAwesomeIcon icon={faBan} color={"red"} size={"2x"} />
                </div>
                <div className={styles.modControlText}>
                    STOP MODERATING
                </div>
                <div style={{clear: "both"}}></div>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                contentLabel="Enter Ticket"
                ariaHideApp={false}
                className={styles.addTicketModal}
            >
                <EnterTicketForm closeModal={closeModal} />
            </Modal>
        </div>
    );
}

export default ModeratorControls;