import {
    updateSessionId,
    updateSessionClients,
    updateRevealScores,
    pingPong,
    updateSessionHasModerator,
    updateSessionHasActiveTicket,
    updateFistOfFive,
    updateActiveTicket,
    updateSessionLabel,
    resetScoreOptions
} from '../features/pointing-session/PointingSessionSlice';

import { updateActiveSessionId, updateClient } from '../AppSlice';

import { getAvailableSessions } from '../features/select-room/SelectRoomSlice';

import { getClientId } from '../AppSlice';

const wsMsgHandlerMap = {
    "getAvailableSessions": getAvailableSessions,
    "getClientId": getClientId,
    "enterSession": [
        updateSessionLabel,
        updateRevealScores,
        updateActiveSessionId,
        updateSessionId,
        updateClient,
        updateSessionClients,
        updateSessionHasModerator,
        updateSessionHasActiveTicket,
        updateFistOfFive,
        updateActiveTicket
    ],
    "closeTicket": [
        updateActiveTicket,
        updateSessionClients,
        updateClient,
        updateFistOfFive,
        resetScoreOptions,
        updateRevealScores,
        updateSessionHasActiveTicket
    ],
    "updateClient": [
        updateClient,
        updateSessionClients,
        updateActiveTicket,
        updateSessionHasModerator,
        updateSessionHasActiveTicket
    ],
    "addTicket": [
        updateActiveTicket,
        updateFistOfFive,
        updateSessionHasActiveTicket
    ],
    "exitSession": [
        updateSessionClients,
        updateActiveTicket,
        updateSessionHasActiveTicket,
        updateSessionHasModerator
    ],
    "removeClientFromSession": [
        updateSessionClients,
        updateActiveTicket,
        updateSessionHasActiveTicket,
        updateSessionHasModerator
    ],
    "revealScores": updateRevealScores,
    "hideScores": updateRevealScores,
    "pingPong": pingPong,
    "forceResetModerator": [
        updateClient,
        updateSessionClients,
        updateActiveTicket,
        updateSessionHasModerator,
        updateSessionHasActiveTicket
    ]
}

export default wsMsgHandlerMap;